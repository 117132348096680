.contact {
  padding: 2rem .6rem;

  ul {
    list-style: none;
  }

  .contact--left {
    padding: .6rem;

    ul {
      list-style: none;

      li::before {
        content: ' ';
        margin-right: .5rem;
      }
      li::marker {
        content: ' ';
      }
    }
  }

  .contact--right {
    margin-top: 1rem;
    padding: 0 .6rem;

    .policy--notice {
      font-size: .9em;
      margin-bottom: 2rem;

      a {
        font-size: inherit;
      }
    }
  }
}

@media only screen and (min-width: 797px) {
  .contact {
    width: 100%;

    .contact--block {
      display: flex;
      flex-direction: row;
      width: 100%;

      .contact--left {
        min-width: 50%;
      }

      .contact--right {
        min-width: 50%;
      }
    }
  }
}

@media only screen and (min-width: 1023px) {
  .contact {
    width: 100%;

    .contact--block {
      display: flex;
      flex-direction: row;
      width: 100%;

      .contact--left {
        min-width: 40%;
      }

      .contact--right {
        min-width: 60%;
      }
    }
  }
}

.map-container {
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto 1rem;
}

.map-container iframe {
  width: 100%;
  height: 450px;
  border: 0;
}
