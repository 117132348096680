button {
  background-color: transparent;
  border: var(--bor);
  border-radius: var(--bor-rad);
  color: var(--pri-500);
  cursor: pointer;
  display: inline-block;
  margin: 0;
  margin-right: .6rem;
  min-width: 10ch;
  outline: none;
  padding: 1.5ch 2ch;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: all var(--dur) var(--trans);

  &:focus,
  &:hover {
    background-color: var(--pri-500);
    border-color: var(--pri-500);
    color: var(--t-color-inv);
    text-decoration: none;
  }

  &:active {
    background-color: var(--pri-800);
    border-color: var(--pri-800);
  }

  &.full-width {
    min-width: 100%;
  }
}

.button {
  background: transparent;
  border: var(--bor);
  border-radius: var(--bor-rad);
  color: var(--pri-500);
  cursor: pointer;
  display: inline-block;
  margin: 0;
  margin-right: .6rem;
  min-width: 10ch;
  outline: none;
  padding: 1.5ch 2ch;
  text-align: center;
  text-decoration: none;
  text-transform: capitalize;
  transition: all var(--dur) var(--trans);

  &:focus,
  &:hover {
    background: var(--pri-500);
    border-color: var(--pri-500);
    color: var(--t-color-inv);
    text-decoration: none;
  }

  &.active {
    background: var(--pri-500);
    border-color: var(--pri-500);

    &:focus,
    &:hover {
      background: var(--pri-500);
      border-color: var(--pri-500);
    }
  }

  &.full-width {
    min-width: 100%;
  }
}

.button-primary {
  background: var(--pri-500);
  border-color: var(--pri-500);
  color: var(--wh-color);

  &:focus,
  &:hover {
    background: var(--pri-500);
    border-color: var(--pri-500);
    color: var(--wh-color);
  }

  &:active {
    background: var(--pri-500);
    border-color: var(--pri-500);
    color: var(--wh-color);
  }
}

.button--submit {
  background: var(--pri-500);
  border: 1px solid var(--pri-500);
  border-radius: var(--bor-rad);
  color: all var(--wh-color);
  cursor: pointer;
  display: inline-block;
  margin: 0 .6rem 0 0;
  outline: none;
  padding: 1.5ch 2ch;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all var(--dur) var(--trans);


  &:focus,
  &:hover {
    background: var(--pri-800);
    color: all var(--wh-color);
  }

  &.full--width {
    width: 100%;
  }
}
