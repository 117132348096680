.testimonial--block {
  background-color: var(--pale-00);
  border: var(--bor);
  border-left: 4px solid var(--pri-500);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow-sm);
  color: var(--t-color);
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: left;

  p {
    margin-bottom: 0;
  }
}
