:root {
  --auto-grid-min-size: 280px;
}

.auto--grid--fill {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size, 16rem), 1fr));
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.auto--grid--fit {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--auto-grid-min-size, 16rem), 1fr));
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
