.post--list--block {
  border: 1px solid var(--gray-100);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow-md);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;

  img {
    margin-bottom: 1rem;
    margin-top: .5rem;
  }

  p {
    margin-bottom: auto;
    padding: 0 .6rem 1rem;
  }

  h2 {
    font-size: var(--heading-h5);
    margin-top: .2em !important;
    padding: 0 .6rem;
  }

  a {
    background-color: var(--pri-500);
    color: var(--wh-color);
    margin: 0;
    margin-top: auto;
    padding: .6rem;
    text-align: center;
    width: 100%;

    &:hover,
    &:focus {
      background-color: var(--pri-800);
      background-size: 0 0;
      color: var(--wh-color);
      font-style: italic;
    }
  }
}


.post--block {
  width: 100%;

  .post--tags {
    margin-left: -1rem;

    li {
      display: inline-block;
    }

    .icon {
      margin-left: 1rem;
      position: relative;
      top: .25rem;
    }
  }


  img {
    height: auto;
    margin: 0;
    width: 100%;
  }

  h1 {
    margin-top: 1rem;
  }

  .button {
    line-height: 1;
    margin-top: 1rem;
  }
}
