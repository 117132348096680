.scrollup {
  background: var(--pri-500);
  border: 3px solid transparent;
  border-radius: var(--bor-rad);
  bottom: 0;
  color: var(--wh-color);
  font-size: 2rem;
  line-height: 1;
  opacity: 1;
  padding: .2rem .4rem;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  transition: all .3s ease-in-out;
  z-index: 9999;

  &:hover {
    border: 3px solid var(--pri-800);
    opacity: .7;
    transform: translateY(-.25em);
  }

  .icon {
    height: 1.5rem;
    margin: 0;
    position: relative;
    top: .15rem;
    width: 1.5rem;
  }
}
