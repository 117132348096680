footer {
  background-color: var(--pale-25);
  color: var(--t-color);
  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 1rem;

  div {
    align-items: center;
    border-top: 1px solid var(--gray-600);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 2rem;
    padding-top: 2rem;
    text-align: left;

    .is--heading {
      text-align: center;
    }

    a {
      color: var(--t-color);
      font-size: inherit;
      transition: all var(--dur) var(--trans);
    }

    a:focus,
    a:hover {
      color: var(--gray-900);
      text-decoration: underline;
    }
  }

  ul {
    margin: .5rem;

    li {
      font-size: inherit;
      margin-left: 0;
    }

    .icon {
      fill: var(--t-color);
    }
  }


  div:nth-child(1) {
    border-top: 0;
  }
}



@media screen and (min-width: 797px) {
  footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    padding: 1.5rem 1rem .5rem;

    div {
      border-top: 0;
      margin: 1rem;
      max-width: 300px;
    }

    .social--links {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 1023px) {
  footer {
    padding: 2rem 4rem;
  }
}
