.navbar,
.navbar-menu,
.navbar-brand,
.navbar-item.has-dropdown {
  background-color: transparent;
}

.navbar-menu {
  padding: .6rem 0 0;
}

.navbar [aria-current="page"] {
  background-color: var(--pri-600);
  color: var(--t-color-inv);
  font-style: italic;
  font-weight: var(--h-font-wght);
  }

  .navbar-brand {
    height: 80px;
  }

  .navbar-brand img {
    height: 60px;
    margin-left: 1rem;
    margin-top: 20px;
    width: auto;
  }

.navbar-item.has-dropdown,
.navbar-item,
.navbar-link,
.navbar-dropdown-arrow {
  color: var(--t-color);
}

a {
  text-decoration: none;
}

.navbar-link.active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  background-color: var(--pri-600);
  color: var(--t-color-inv);
}

.navbar-dropdown {
  background-color: var(--gray-600);
  color: var(--t-color-inv);
}

.navbar-dropdown a.navbar-item {
  color: var(--t-color-inv);
}

.navbar-dropdown a.navbar-item:hover {
  background-color: var(--pri-600);
  color: var(--t-color-inv);
}

.navbar-item .icon:only-child,
.navbar-link .icon:only-child {
  margin-left: .25rem;
  margin-right: .25rem;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: var(--t-color);
}

.navbar-burger {
  margin-right: 2rem;
}

.navbar-burger span {
  background-color: var(--t-color);
}

.navbar-dropdown {
  display: none;
}

.navbar-dropdown.is-active {
  display: block;
}

.navbar-item.has-dropdown.is-active > .navbar-link {
  background-color: var(--pri-600);
  color: var(--t-color-inv);
}

@media screen and (min-width: 1023px) {
  .navbar {
    align-content: center;
    display: flex;
    justify-content: center;
  }

  .navbar-menu {
    flex-grow: 0;
    flex-shrink: 0;
  }

  .navbar-item.has-dropdown.is-active .navbar-link,
  .navbar-item.has-dropdown:focus .navbar-link,
  .navbar-item.has-dropdown:hover .navbar-link {
    background-color: var(--pri-600);
    color: var(--t-color-inv);
  }
}
