label {
  display: block;
  font-size: var(--small);
  margin-bottom: 0.3em;
  opacity: 0.9;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
input[type=number],
input[type=search],
input[type=textarea],
select,
textarea {
  appearance: none;
  background-color: var(--pale-00);
  border: var(--bor);
  border-radius: var(--bor-rad);
  font-family: inherit;
  height: auto;
  margin-bottom: 1rem;
  margin-left: 0;
  outline: none;
  padding: 1.5ch 2ch;
  transition: all var(--dur) var(--trans);
  width: 100%;

  &:focus {
    border-color: var(--pri-500);
    box-shadow: var(--shadow-lg);
  }
}

input[type=submit] {
  appearance: none;
  background-color: var(--pri-500);
  border: var(--bor);
  border-radius: var(--bor-rad);
  color: var(--wh-color);
  font-family: inherit;
  font-size: 1.2em;
  height: auto;
  margin-bottom: 1rem;
  margin-left: 0;
  outline: none;
  padding: 1.5ch 2ch;
  text-align: center;
  transition: all var(--dur) var(--trans);
  width: 100%;

  &:focus,
  &:hover {
    background-color: var(--pri-800);
    box-shadow: var(--shadow-lg);
    font-style: italic;
  }
}

textarea {
  height: 12rem;
  overflow: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-text-fill-color: var(--pri-500);
  transition: background-color 5000s ease-in-out 0s;
}

form {
  margin: 2rem 0;
  padding: 0;
}

.inline--form {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  div {
    flex: 1 1 calc(50% - 0.5rem);
  }
}

@media (max-width: 768px) {
  .inline--form {
    flex-direction: column;
  }

  .inline--form div {
    flex: 1 1 100%;
  }
}
