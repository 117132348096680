.search--query {
  display: flex;
  flex-direction: row;

  input:first-of-type {
    border-radius: var(--bor-rad) 0 0 var(--bor-rad);
  }

  input:last-of-type {
    background-color: var(--pale-00);
    border-left: 0;
    border-radius: 0 var(--bor-rad) var(--bor-rad) 0;
    cursor: pointer;
    width: 2.5rem;
  }

  .icon {
    color: var(--t-color);
    fill: var(--t-color);
    position: relative;
    right: 1.8rem;
    top: 1.5rem;
  }
}

.search--results {
  margin-top: 1em;

  li {
    padding: 1ch;
  }

  li:nth-child(odd) {
    background-color: var(--gray-25);
  }
}
