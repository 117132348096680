:root {
	--lightness-00: 98%;
	--lightness-25: 95%;
	--lightness-50: 93%;
	--lightness-100: 88%;
	--lightness-200: 80%;
	--lightness-300: 72%;
	--lightness-400: 60%;
	--lightness-500: 50%;
	--lightness-600: 40%;
	--lightness-700: 32%;
	--lightness-800: 24%;
	--lightness-900: 16%;

	--t-color: oklch(15% 0.01 0);
	--t-color-inv: oklch(100% 0.01 0);
	--bkgd-color: oklch(100% 0.01 0);
	--blk-color: oklch(12% 0 0);
	--wh-color: oklch(100% 0 0);
	--accent-color: oklch(var(--lightness-100) 0.27 var(--sec-hue));

	--pri-100: oklch(var(--lightness-100) 0.02 var(--pri-hue));
	--pri-200: oklch(var(--lightness-200) 0.05 var(--pri-hue));
	--pri-300: oklch(var(--lightness-300) 0.12 var(--pri-hue));
	--pri-400: oklch(var(--lightness-400) 0.19 var(--pri-hue));
	--pri-500: oklch(var(--lightness-500) 0.27 var(--pri-hue));
	--pri-600: oklch(var(--lightness-500) 0.19 var(--pri-hue));
	--pri-700: oklch(var(--lightness-700) 0.12 var(--pri-hue));
	--pri-800: oklch(var(--lightness-800) 0.05 var(--pri-hue));
	--pri-900: oklch(var(--lightness-900) 0.02 var(--pri-hue));

	--sec-100: oklch(var(--lightness-100) 0.02 var(--sec-hue));
	--sec-200: oklch(var(--lightness-200) 0.05 var(--sec-hue));
	--sec-300: oklch(var(--lightness-300) 0.12 var(--sec-hue));
	--sec-400: oklch(var(--lightness-400) 0.19 var(--sec-hue));
	--sec-500: oklch(var(--lightness-500) 0.27 var(--sec-hue));
	--sec-600: oklch(var(--lightness-600) 0.19 var(--sec-hue));
	--sec-700: oklch(var(--lightness-700) 0.12 var(--sec-hue));
	--sec-800: oklch(var(--lightness-800) 0.05 var(--sec-hue));
	--sec-900: oklch(var(--lightness-900) 0.02 var(--sec-hue));

	--success-100: oklch(var(--lightness-100) 0.02 var(--success-hue));
	--success-200: oklch(var(--lightness-200) 0.05 var(--success-hue));
	--success-300: oklch(var(--lightness-300) 0.12 var(--success-hue));
	--success-400: oklch(var(--lightness-400) 0.19 var(--success-hue));
	--success-500: oklch(var(--lightness-500) 0.27 var(--success-hue));
	--success-600: oklch(var(--lightness-600) 0.19 var(--success-hue));
	--success-700: oklch(var(--lightness-700) 0.12 var(--success-hue));
	--success-800: oklch(var(--lightness-800) 0.05 var(--success-hue));
	--success-900: oklch(var(--lightness-900) 0.02 var(--success-hue));

	--warning-100: oklch(var(--lightness-100) 0.02 var(--warning-hue));
	--warning-200: oklch(var(--lightness-200) 0.05 var(--warning-hue));
	--warning-300: oklch(var(--lightness-300) 0.12 var(--warning-hue));
	--warning-400: oklch(var(--lightness-400) 0.19 var(--warning-hue));
	--warning-500: oklch(var(--lightness-500) 0.27 var(--warning-hue));
	--warning-600: oklch(var(--lightness-600) 0.19 var(--warning-hue));
	--warning-700: oklch(var(--lightness-700) 0.12 var(--warning-hue));
	--warning-800: oklch(var(--lightness-800) 0.05 var(--warning-hue));
	--warning-900: oklch(var(--lightness-900) 0.02 var(--warning-hue));

	--danger-100: oklch(var(--lightness-100) 0.02 var(--danger-hue));
	--danger-200: oklch(var(--lightness-200) 0.05 var(--danger-hue));
	--danger-300: oklch(var(--lightness-300) 0.12 var(--danger-hue));
	--danger-400: oklch(var(--lightness-400) 0.19 var(--danger-hue));
	--danger-500: oklch(var(--lightness-500) 0.27 var(--danger-hue));
	--danger-600: oklch(var(--lightness-600) 0.19 var(--danger-hue));
	--danger-700: oklch(var(--lightness-700) 0.12 var(--danger-hue));
	--danger-800: oklch(var(--lightness-800) 0.05 var(--danger-hue));
	--danger-900: oklch(var(--lightness-900) 0.02 var(--danger-hue));

	--gray-00: oklch(var(--lightness-00) var(--gray-chroma) var(--gray-hue));
	--gray-25: oklch(var(--lightness-25) var(--gray-chroma) var(--gray-hue));
	--gray-50: oklch(var(--lightness-50) var(--gray-chroma) var(--gray-hue));
	--gray-100: oklch(var(--lightness-100) var(--gray-chroma) var(--gray-hue));
	--gray-200: oklch(var(--lightness-200) var(--gray-chroma) var(--gray-hue));
	--gray-300: oklch(var(--lightness-300) var(--gray-chroma) var(--gray-hue));
	--gray-400: oklch(var(--lightness-400) var(--gray-chroma) var(--gray-hue));
	--gray-500: oklch(var(--lightness-500) var(--gray-chroma) var(--gray-hue));
	--gray-600: oklch(var(--lightness-600) var(--gray-chroma) var(--gray-hue));
	--gray-700: oklch(var(--lightness-700) var(--gray-chroma) var(--gray-hue));
	--gray-800: oklch(var(--lightness-800) var(--gray-chroma) var(--gray-hue));
	--gray-900: oklch(var(--lightness-900) var(--gray-chroma) var(--gray-hue));

	--pale-00: oklch(var(--lightness-00) var(--pale-chroma) var(--pale-hue));
	--pale-25: oklch(var(--lightness-25) var(--pale-chroma) var(--pale-hue));
	--pale-50: oklch(var(--lightness-50) var(--pale-chroma) var(--pale-hue));
}

@media (prefers-color-scheme: dark) {
	:root {
		--lightness-00: 40%;
		--lightness-25: 40%;
		--lightness-50: 40%;
		--lightness-100: 40%;
		--lightness-200: 50%;
		--lightness-300: 60%;
		--lightness-400: 60%;
		--lightness-500: 30%;
		--lightness-600: 30%;
		--lightness-700: 30%;
		--lightness-800: 30%;
		--lightness-900: 30%;
		--t-color: oklch(100% 0.01 0);
		--t-color-inv: oklch(100% 0.01 0);
		--bkgd-color: oklch(30% 0.01 0);
	}
}

[data-theme="dark"] {
	--lightness-00: 40%;
	--lightness-25: 40%;
	--lightness-50: 40%;
	--lightness-100: 40%;
	--lightness-200: 50%;
	--lightness-300: 60%;
	--lightness-400: 60%;
	--lightness-500: 30%;
	--lightness-600: 30%;
	--lightness-700: 30%;
	--lightness-800: 30%;
	--lightness-900: 30%;
	--t-color: oklch(100% 0.01 0);
	--t-color-inv: oklch(100% 0.01 0);
	--bkgd-color: oklch(30% 0.01 0);
}
