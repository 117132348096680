.banner {
  background-color: var(--bkgd-color);
  box-shadow: var(--shadow-sm);
  margin: 0 1.5rem;
  padding: 1rem;
  position: relative;
  text-align: center;
  top: -4rem;
  z-index: 99;

  a img {
    height: 80px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 797px) {
  .banner {
    margin: 0 auto;
    max-width: 60ch;
    padding: 1.5rem;
    top: -3rem;
    a img {
      height: 100px;
    }
  }
}


@media screen and (min-width: 1023px) {
  .banner {
    max-width: 70ch;
    top: -5rem;
    a img {
      height: 110px;
    }
  }
}


@media screen and (min-width : 1224px) {
  .banner {
    max-width: 75ch;
    top: -5rem;
    a img {
      height: 120px;
    }
  }
}

@media screen and (min-width: 1439px) {
  .banner {
    max-width: 80ch;
    top: -5rem;
    a img {
      height: 130px;
    }
  }
}
