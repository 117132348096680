.copyright {
  background-color: var(--pale-50);
  color: var(--t-color);
  display: grid;
  font-size: 14px;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 1rem;

  .icon {
    height: 14px;
    margin-right: 0;
    width: 14px;
  }

  div {
    display: grid;
    place-content: center;

  ul {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none outside none;
    margin: 0;
    margin-left: 1rem;

    li {
      font-size: inherit;
      margin: 0;
      padding: .4rem;
    }

    .icon {
      fill: var(--t-color);
    }
  }


  p {
    font-size: inherit;
    margin: 1vmin 0 .25vmin;
    padding: 1rem 0;
    text-align: center;

    .icon {
      fill: var(--t-color);
    }
  }

  a {
    color: var(--t-color);
    font-size: inherit;
    margin: 1rem auto;
    padding: 1rem 0;
    transition: all var(--dur) var(--trans);

    &:focus,
    &:hover {
      color: var(--gray-800);
      text-decoration: underline;
    }
  }
}

  .image--grid {
    display: grid;
    grid-gap: .6rem;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 2rem;
  }

  img,
  picture {
    border-radius: var(--bor-rad);
    max-height: 50px;
  }
  .social--links {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;

    a {
      background-color: var(--pri-600);
      border: 1px solid var(--wh-color);
      border-radius: var(--bor-rad);
      color: var(--pri-500);
      margin: 1rem;
      padding: 0;
    }

    .icon {
      fill: var(--wh-color);
      height: 1.5rem;
      margin: 1rem 1rem .6rem;
      position: relative;
      top: 0;
      transform: scale(1);
      transition: all var(--dur) var(--trans);
      width: 1.5rem;
    }

    .icon:focus,
    .icon:hover {
      transform: scale(1.4);
    }
  }
}


@media only screen and (min-width: 797px) {
  .copyright {
    grid-template-columns: auto auto auto auto;

    p {
      margin: 0 0 .25vmin;
      padding: 1rem 0;
    }

    ul {
      align-content: center;
      flex-direction: row;
      justify-content: center;
      padding: 1rem;


      li {
        padding: 0 1rem;
      }
    }

    hr {
      display: none;
    }

    .image--grid {
      margin-top: 0;
    }
  }
}
