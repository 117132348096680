header {
  color: var(--wh-color);
  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
  overflow: hidden;
  padding: 1rem 0 0;
  place-content: center;
  position: relative;
  width: 100%;

  div {
    background: var(--wh-color);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    height: 100%;
    padding: 6rem 1rem;
    place-content: center;
    width: 100%;
    z-index: 1;
  }
}

.short--header {
  background-color: var(--pale-50);
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><rect width='100%' height='100%' fill='transparent'/><svg x='10' y='5' width='50' height='50' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'><path d='M520 48L393.3 48C381 19.7 352.8 0 320 0s-61 19.7-73.3 48L120 48c-13.3 0-24 10.7-24 24s10.7 24 24 24l121.6 0c5.8 28.6 26.9 51.7 54.4 60.3L296 464l-176 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l200 0 200 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-176 0 0-307.7c27.5-8.6 48.6-31.7 54.4-60.3L520 96c13.3 0 24-10.7 24-24s-10.7-24-24-24zm-8 147.8L584.4 320l-144.9 0L512 195.8zM386 337.1C396.8 382 449.1 416 512 416s115.2-34 126-78.9c2.6-11-1-22.3-6.7-32.1L536.1 141.8c-5-8.6-14.2-13.8-24.1-13.8s-19.1 5.3-24.1 13.8L392.7 305.1c-5.7 9.8-9.3 21.1-6.7 32.1zM54.4 320l72.4-124.2L199.3 320 54.4 320zm72.4 96c62.9 0 115.2-34 126-78.9c2.6-11-1-22.3-6.7-32.1L150.9 141.8c-5-8.6-14.2-13.8-24.1-13.8s-19.1 5.3-24.1 13.8L7.6 305.1c-5.7 9.8-9.3 21.1-6.7 32.1C11.7 382 64 416 126.8 416zM320 48a32 32 0 1 1 0 64 32 32 0 1 1 0-64z' fill='%23d3d3d3'/></svg></svg>"),url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100'><rect width='100%' height='100%' fill='transparent'/><svg x='50' y='50' width='50' height='50' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M313 7c9.3 9.3 9.4 24.3 .2 33.7L471.3 198.8c9.4-9.2 24.4-9.1 33.7 .2c9.4 9.4 9.4 24.6 0 33.9l-16.7 16.8L393 345l-16 16c-9.4 9.4-24.6 9.4-33.9 0c-9-9-9.4-23.5-.9-32.9L184 169.9c-9.4 8.4-23.9 8.1-32.9-.9c-9.4-9.4-9.4-24.6 0-33.9l16-16 95.2-95.3L279 7c9.4-9.4 24.6-9.4 33.9 0zM279.2 74.7L217.9 136 376 294.1l61.3-61.3L279.2 74.7zM223.4 254.6l33.9 33.9-49.8 49.8 7 7c12.5 12.5 12.5 32.8 0 45.3l-112 112c-12.5 12.5-32.8 12.5-45.3 0l-48-48c-12.5-12.5-12.5-32.8 0-45.3l112-112c12.5-12.5 32.8-12.5 45.3 0l7 7 49.8-49.8zm-79.4 88L54.6 432 80 457.4 169.4 368 144 342.6z' fill='%23d3d3d3'/></svg></svg>");
  background-size: 100px 100px;
  background-repeat: repeat;
  padding: 3rem 1rem;
  color: var(--t-color);
}

@media screen and (min-width: 797px) {
  header {
    div {
      padding: 5rem 2rem;
    }
  }
}

@media screen and (min-width: 1023px) {
  header {
    div {
      padding: 7rem 2rem;
    }

  }
}

@media screen and (min-width: 1224px) {
  header {
    div {
      padding: 11rem 2rem;
    }
  }
}

@media screen and (min-width: 1439px) {
  header {
    div {
      padding: 14rem 3rem;
    }
  }
}
