.frontpage--block--container {
  background-color: var(--gray-25);
  margin-bottom: 2rem;
  margin-left: 50%;
  margin-top: 2rem;
  padding: 5vh 1rem;
  transform: translateX(-50%);
  width: 100vw;

  .auto--grid--fit {
    --auto-grid-min-size: 280px;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(1, minmax(var(--auto-grid-min-size, 16rem), 1fr));
    margin-left: auto;
    margin-right: auto;

    .frontpage--block {
      background-color: transparent;
      color: var(--t-color);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1rem;
      text-align: left;

      .block--heading {
        display: flex;
        flex-wrap: row wrap;
        justify-content: center;
      }

      h2 {
        margin-top: 0.3rem;
        padding-left: 1rem;
      }

      p {
        margin-bottom: auto;
        padding: 1.5ch;
      }

      a {
        color: var(--t-color);
        font-style: normal;
        font-weight: 600;
        margin: 0;
        margin-top: auto;
        padding: 1.5ch;
        text-align: center;
        transition: all var(--dur) var(--trans);
        width: 100%;

        &:focus,
        &:hover {
          font-style: italic;
          text-decoration: none;
        }
      }
    }

    .frontpage--block:nth-of-type(1) {

      .icon--holder {
        background-color: var(--gray-200);
        border-radius: 50%;
        height: 60px;
        margin-left: 2rem;
        width: 60px;

        svg {
          height: 40px;
          left: 10px;
          position: relative;
          top: 10px;
          width: 40px;
        }
      }
    }

    .frontpage--block:nth-of-type(2) {
      border-bottom: 1px dotted var(--gray-500);
      border-top: 1px dotted var(--gray-500);

      .icon--holder {
        background-color: var(--pri-200);
        border-radius: 50%;

        height: 60px;
        margin-left: 2rem;
        width: 60px;

        svg {
          height: 40px;
          left: 10px;
          position: relative;
          top: 10px;
          width: 40px;
        }
      }
    }

    .frontpage--block:nth-of-type(3) {
      border-bottom: 1px dotted var(--gray-500);

      .icon--holder {
        background-color: var(--sec-200);
        border-radius: 50%;

        height: 60px;
        margin-left: 2rem;
        width: 60px;

        svg {
          height: 40px;
          left: 10px;
          position: relative;
          top: 10px;
          width: 40px;
        }
      }
    }

    .frontpage--block:nth-of-type(4) {
      border-bottom: 1px dotted var(--gray-500);
      .icon--holder {
        background-color: var(--gray-200);
        border-radius: 50%;

        height: 60px;
        margin-left: 2rem;
        width: 60px;

        svg {
          height: 40px;
          left: 10px;
          position: relative;
          top: 10px;
          width: 40px;
        }
      }
    }

    .frontpage--block:nth-of-type(5) {
      border-bottom: 1px dotted var(--gray-500);
      .icon--holder {
        background-color: var(--pri-200);
        border-radius: 50%;

        height: 60px;
        margin-left: 2rem;
        width: 60px;

        svg {
          height: 40px;
          left: 10px;
          position: relative;
          top: 10px;
          width: 40px;
        }
      }
    }

    .frontpage--block:nth-of-type(6) {
      .icon--holder {
        background-color: var(--sec-200);
        border-radius: 50%;

        height: 60px;
        margin-left: 2rem;
        width: 60px;

        svg {
          height: 40px;
          left: 10px;
          position: relative;
          top: 10px;
          width: 40px;
        }
      }
    }
  }
}

.articles {
  background-color: var(--pale-25);
  max-width: 100%;

  .articles--wrapper {
    height: calc(300px + 4rem);
    min-height: 0;
    min-width: 0;
    padding: 2rem 1rem;

    .splide__slide {
      background-color: var(--gray-700);
      height: 300px;
    }


    .splide__slide img {
      height: 300px;
      object-fit: cover;
      position: relative;
      width: 100%;
    }

    a {
      cursor: pointer;
    }

    p {
      color: var(--t-color-inv);
      padding-left: 1.5em;
      position: absolute;
      text-shadow: 1px 1px var(--gray-800);
      top: 10%;
      z-index: 999;
    }
  }
}

@media screen and (min-width: 797px) {
  .frontpage--block--container {
    margin-bottom: 3rem;
    margin-top: 3rem;

    .auto--grid--fit {
      gap: 0;
      grid-template-columns: repeat(3, minmax(var(--auto-grid-min-size, 16rem), 1fr));

      .frontpage--block {
        padding: 1.5rem;

        h2 {

          padding-left: 1.5rem;
        }

        p {

          padding: 1.5rem;
        }

      }

      .frontpage--block:nth-of-type(1) {
        border-bottom: 1px dotted var(--gray-500);
        border-top: none;
      }

      .frontpage--block:nth-of-type(2) {
        border-left: 1px dotted var(--gray-500);
        border-right: 1px dotted var(--gray-500);
        border-top: none;
      }

      .frontpage--block:nth-of-type(4) {
        border-bottom: none;
        border-top: none;
      }

      .frontpage--block:nth-of-type(5) {
        border-bottom: none;
        border-left: 1px dotted var(--gray-500);
        border-right: 1px dotted var(--gray-500);
        border-top: none;
      }
    }
  }
}

@media screen and (min-width: 1023px) {
  .frontpage--block--container {
    margin-bottom: 4rem;
    margin-top: 4rem;
    .auto--grid--fit {

      .frontpage--block {
        padding: 2.5rem;
 }
}}

  .articles {

    .articles--wrapper {
      height: calc(300px + 6rem);
      padding: 3rem;
    }
  }
}
