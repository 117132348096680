hr {
  border: 0;
  border-bottom: px solid var(--gray-300);
  margin: 2rem 0 1.5rem;
}

.visually--hidden {
  clip: rect(1px 1px 1px 1px);
  clip-path: inset(1px);
  display: block;
  height: 1px;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  width: 1px;
}

.is--hidden {
  display: none;
}

.block {
  background-color: var(--pale-00);
  border: var(--bor);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow-sm);
  color: var(--t-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  text-align: left;

  img {
    margin-bottom: 0;
  }
}

.vertical--spacer {
  height: 3rem
}

.centered{
  display: grid;
  place-content: center;
  text-align: center;
}


.flow>*+* {
  margin-top: 1em;
}

.n--wrapper {
  margin: 0 auto;
  max-width: var(--n-width, 80rem);
  padding: 0 1rem;
  width: 100%;
}

.m--wrapper {
  margin: 0 auto;
  max-width: var(--m-width, 80rem);
  padding: 0 1rem;
  width: 100%;
}

.w--wrapper {
  margin: 0 auto;
  max-width: var(--w-width, 80rem);
  padding: 0 1rem;
  width: 100%;
}

.xw--wrapper {
  margin: 0 auto;
  max-width: var(--xw-width, 90rem);
  padding: 0 1rem;
  width: 100%;
}

.complete--width {
  width: 100%;
}

.two--column {
  background-color: var(--pale-25);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow-sm);
  display: grid;
  grid-template-columns: 1fr;

  div:first-of-type {
    border-bottom: var(--bor-dot);
    padding-bottom: 2.5rem;
  }

  .image--grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr;
    padding: 1rem;

    img {
      height: 150px;
      margin: 0 auto;
      width: auto;
    }
  }
}

@media screen and (min-width: 1023px) {
  .two--column {
    grid-template-columns: 1fr 1fr;
    margin-top: 3rem;

    div:first-of-type {
      border-bottom: none;
      border-right: var(--bor-dot);
      padding-bottom: 2.5rem;
    }

    .image--grid {
      gap: 1.5rem;
      grid-template-columns: repeat(2, 1fr);

      img {
        height: 120px;
      }
    }
  }
  .vertical--spacer {
    height: 4rem
  }
}
