:root {
  --pri-hue: 23;
  --sec-hue: 22;
  --accent-hue: 278;
  --success-hue: 156;
  --warning-hue: 72;
  --danger-hue: 28;
  --gray-hue: 195;
  --gray-chroma: 0;
  --pale-hue: 90;
  --pale-chroma: 0;

  --bor-color: var(--pri-500);
  --bor-rad: 6px;
  --bor-width: 2px;
  --b-font: 'Open Sans', sans-serif;
  --h-font: 'PT Serif', serif;
  --l-spacing: 0.05em;
  --w-spacing: 0.07em;
  --h-pos: left;
  --f-wght: 400;
  --h-font-wght: 400;
  --line-height: calc(1.1em + 0.5rem);
  --line-height-list: 1.7;
  --line-height-headings: 1.2;
  --dur: 0.35s;
  --trans: ease-in-out;
  --cont-width: 100ch;
  --n-width: 70ch;
  --m-width: 100ch;
  --w-width: 130ch;
  --xw-width: 160ch;
  --inner-shadow: 0px 1px 1px var(--sec-500);
  --shadow-sm: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  --shadow: 0 1px 1px rgba(0, 0, 0, 0.10), 0 2px 2px rgba(0, 0, 0, 0.10), 0 4px 4px rgba(0, 0, 0, 0.10);
  --shadow-md: 0 1px 1px rgba(0, 0, 0, 0.10), 0 2px 2px rgba(0, 0, 0, 0.10), 0 4px 4px rgba(0, 0, 0, 0.10), 0 6px 8px rgba(0, 0, 0, 0.10);
  --shadow-lg: 0 2px 1px rgba(0, 0, 0, 0.09), 0 4px 2px rgba(0, 0, 0, 0.09), 0 8px 4px rgba(0, 0, 0, 0.09), 0 16px 8px rgba(0, 0, 0, 0.09),
  0 32px 16px rgba(0, 0, 0, 0.09);
}

