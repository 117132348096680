main {
  display: flex;
  flex-direction: column;
  margin: 2rem auto;
  padding: 0 0 1rem;

  a {
    color: var(--pri-500);
    font-style: italic;
    font-weight: inherit;
    text-decoration: none;
    transition: all .35s var(--trans);

    &:focus,
    &:hover {
      color: var(--sec-500);
    }
  }

  section {
    margin: 0 auto;
    max-inline-size: var(--w-width);
    padding: 1rem;
    width: 100%;
  }

  article {
    margin: 0 auto;
    max-inline-size: var(--n-width);
    padding: 1rem 1.5rem;

    figure img {
      float: right;
      margin-bottom: .5em;
      margin-left: 1em;
      width: 250px;
    }
  }

  .tags {
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;

    li {

      background: var(--pri-300);
      border-radius: var(--bor-rad);
      margin: .2em;
      opacity: .8;
      padding: .2em .4em;

      a {
        color: var(--wh-color);
        font-size: .8em;
      }
    }
  }

  ul {
    list-style: none;
    list-style-position: inside;
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    white-space: nowrap;

    li {
      list-style-type: circle;
    }
  }

  .has--aside {
    display: grid;
    grid-template-columns: 1fr;

    aside {
      padding-right: 1rem;
      padding-top: 0;

      nav {
        padding: 0 .6rem;

        ol {
          list-style: inside none;
          line-height: 2.2;
          margin-left: 0;

          a {
            background-color: var(--pale-25);
            border-radius: var(--bor-rad);
            color: var(--pri-500);
            font-size: .85em;
            font-weight: 500;
            padding: .5rem;
            .icon {
              margin-left: .3em;
              margin-right: 0;
            }
          }
        }

        ul {
          list-style: none;
          min-width: 100%;

          li {
            background-color: var(--pale-25);
            border-radius: var(--bor-rad);
            min-width: 100%;
            margin: .3rem 0;
            padding: .5rem;
          }

          li::before {
            content: ' ';
          }

          li::marker {
            content: ' ';
          }

          a {
            background-color: transparent;
            color: var(--pri-500);
            font-size: .85em;
            font-style: normal;
            line-height: 1.6;
            transition: all var(--dur) var(--trans);

            &:hover {
              font-style: italic;
            }
            .icon {
              margin-left: .3em;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.review--block {
  background-color: var(--pale-00);
  border: var(--bor);
  border-radius: var(--bor-rad);
  box-shadow: var(--shadow-sm);
  color: var(--t-color);
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.sidebar {
  position: fixed;
  right: -350px;
  top: 0;
  width: 350px;
  height: 100%;
  background-color: var(--bkgd-color);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
  padding: 15px;
}

.sidebar-visible {
  right: 0;
}

.sidebar-hidden {
  right: -350px;
}

#sidebarToggle {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 80px;
  background-color: var(--pri-600);
  color: var(--t-color-inv);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px 0 0 8px;
  transition: background-color 0.2s ease-in-out;
  z-index: 1001;
}

#sidebarToggle:hover {
  background-color: var(--pri-900);
}

@media screen and (min-width: 797px) {
  main {
    margin: 3rem auto;

    .has--aside {
      grid-template-columns: 45ch 1fr;

      aside {

        nav {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
        }
      }
    }
  }
}


