@font-face {
  font-display: swap;
  font-family: 'PT Serif';
  font-style: normal;
  font-weight: 400;
  src: local('PT Serif'), local('PTSerif-Regular'),
    url('/assets/fonts/pt-serif-v9-latin-regular.woff2') format('woff2'),
    url('/assets/fonts/pt-serif-v9-latin-regular.woff') format('woff');
}


@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/open-sans-v40-latin-regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/open-sans-v40-latin-500.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/open-sans-v40-latin-600.woff2') format('woff2');
}


:root {
  --default-screen: 1080;
  --header-text: clamp(42px, calc((38 / (var(--default-screen) / 100)) * 1vw), 72px);
  --heading-h1: clamp(34px, calc((44 / (var(--default-screen) / 100)) * 1vw), 48px);
  --heading-h2: clamp(30px, calc((32 / (var(--default-screen) / 100)) * 1vw), 36px);
  --heading-h3: clamp(26px, calc((28 / (var(--default-screen) / 100)) * 1vw), 30px);
  --heading-h4: clamp(22px, calc((24 / (var(--default-screen) / 100)) * 1vw), 28px);
  --heading-h5: clamp(20px, calc((20 / (var(--default-screen) / 100)) * 1vw), 24px);
  --heading-h6: clamp(16px, calc((18 / (var(--default-screen) / 100)) * 1vw), 20px);
  --paragraph: clamp(18px, calc((18 / (var(--default-screen) / 100)) * 1vw), 18px);
  --small: clamp(12px, calc((12 / (var(--default-screen) / 100)) * 1vw), 14px);

  --space-default: 1rem;

  --space-l1-lg: 4rem;
  --space-l2-lg: 3rem;
  --space-l3-lg: 2.5rem;
  --space-l4-lg: 2rem;
  --space-l5-lg: 1.5rem;
  --space-l6-lg: 1rem;

  --space-l1-sm: calc(var(--space-l1-lg) / 4);
  --space-l2-sm: calc(var(--space-l2-lg) / 4);
  --space-l3-sm: calc(var(--space-l3-lg) / 4);
  --space-l4-sm: calc(var(--space-l4-lg) / 4);
  --space-l5-sm: calc(var(--space-l5-lg) / 4);
  --space-l6-sm: calc(var(--space-l6-lg) / 4);

  --space-list-items: 0.3rem;
}

p,
li,
a,
blockquote,
input,
button {
  font-size: var(--paragraph);
}

small,
.is--meta {
  font-size: var(--small);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  --l-spacing: 0;
  --w-spacing: 0;
  font-family: var(--h-font);
  font-weight: var(--h-font-wght);
  letter-spacing: var(--l-spacing);
  line-height: var(--line-height-headings);
  text-align: var(--h-pos);
  text-transform: capitalize;
  word-spacing: var(--w-spacing);
}

h1 {
  font-size: var(--heading-h1);
  margin-bottom: .4em;
  text-align: center;
}

h2 {
  font-size: var(--heading-h2);
  margin-bottom: .3em;
  margin-top: .6em;
}

h3 {
  font-size: var(--heading-h3);
  margin-bottom: .3em;
  margin-top: .6em;
}

h4 {
  font-size: var(--heading-h4);
  margin-bottom: .3em;
  margin-top: .6em;
}

h5 {
  font-size: var(--heading-h5);
  margin-bottom: .3em;
  margin-top: .6em;
}

h6 {
  font-size: var(--heading-h6);
  margin-bottom: .3em;
  margin-top: .6em;
}

:is(p, ul, ol, dl) {
  margin-bottom: .5em;
}

:where(li):has(+ li) {
  margin-bottom: .3em;
}

:where(li) :is(ul, ol) {
  margin-top: 1em;
}

:where(p):has(+ h2) {
  margin-bottom: 0;
}

:where(p):has(+ h3) {
  margin-bottom: 0;
}

:where(p):has(+ h4) {
  margin-bottom: 0;
}

:where(p):has(+ h5) {
  margin-bottom: 0;
}

:where(p):has(+ h6) {
  margin-bottom: 0;
}

p,
li,
a,
blockquote,
small {
  font-family: var(--b-font);
  font-weight: inherit;
}

body,
html {
  font-family: var(--b-font);
  font-style: normal;
  font-weight: var(--f-wght);
  letter-spacing: var(--l-spacing);
  line-height: var(--line-height);
  text-rendering: optimizeLegibility;
  word-spacing: var(--w-spacing);
}

p {
  overflow-wrap: break-word;
  white-space: normal;
  word-break: keep-all;

  &.is--indented {
    text-indent: 1em;
  }
}

.paragraph--heading {
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.5rem;
  margin-top: 2.5rem;
  text-transform: capitalize;
}

small {
  font-style: italic;
}

blockquote {
  background-color: var(--gray-100);
  border-left: 5px solid var(--pri-500);
  border-radius: var(--bor-rad);
  color: var(--f-color);
  font-style: italic;
  font-weight: var(--f-wght);
  margin: 0 0 1rem;
  padding: .5em;

  p {
    margin-bottom: .5em;
  }
}

mark {
  background-color: var(--pri-500);
  color: var(--f-color-inv);
}

.is--meta {
  color: var(--gray-400);
  font-size: .8em;

  a {
    font-size: inherit;
  }
}

.is--bold,
b,
strong {
  font-weight: 600;
}

.is--italic {
  font-style: italic;
}

.is--heading,
.header-anchor {
  color: var(--f-color);
  font-family: var(--h-font);
  font-size: var(--heading-h5);
  font-weight: var(--h-font-wght);
  letter-spacing: var(--l-spacing);
  line-height: var(--line-height-headings);
  margin-bottom: 1rem;
  margin-top: 0;
  padding: .3em;
  text-transform: capitalize;
  width: 100%;
  word-spacing: var(--w-spacing);
}

.is--link--heading {
  font-family: var(--h-font);
  font-size: var(--heading-h3);
  font-weight: var(--h-font-wght);
  letter-spacing: var(--l-spacing);
  line-height: var(--line-height-headings);
  margin-bottom: .5em;
  margin-right: 1rem;
  margin-top: .8em;
  text-transform: capitalize;
  word-spacing: var(--w-spacing);

  a {
    font-family: inherit;
    font-size: inherit;
  }
}

@media screen and (min-width: 797px) {
  body {
    text-rendering: optimizeSpeed;
  }
}
