.sitemap--list,
.sitemap--sublist {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.sitemap--list li::before,
.sitemap--sublist li::before {
  content: '';
  margin-right: .4rem;

  &:a {
    background-image: linear-gradient(transparent calc(100% - 2px), currentColor 2px);
    background-repeat: no-repeat;
    background-size: 0% 100%;
    color: var(--pri-500);
    font-style: italic;
    font-weight: inherit;
    text-decoration: none;
    transition: background-size .35s var(--trans);

    &:focus,
    &:hover {
      background-size: 100% 100%;
      color: var(--pri-500);
    }
  }
}

.sitemap--sublist li{
  margin-left: 1rem;
}
