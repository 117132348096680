@media (prefers-reduced-motion: reduce) {
  .animation {
    animation: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .animation {
    will-change: transform;
  }
}

@media (prefers-reduced-motion: no-preference) {
  @media screen and (min-width: 768px) {
    .fade-left-desktop,
    .fade-right-desktop {
      opacity: 0;
      transition: 1500ms all ease-in-out;
      -webkit-transition: 1500ms all ease-in-out;
    }

    .fade-left-desktop {
      transform: translateX(50px);
    }

    .fade-right-desktop {
      transform: translateX(-50px);
    }

    .fade-left-desktop.animate,
    .fade-right-desktop.animate {
      opacity: 1;
      transform: translateX(0);
    }

    .fade-down-desktop,
    .fade-up-desktop {
      opacity: 0;
      transform: translateY(50px);
      transition: 1500ms all ease-in-out;
      -webkit-transition: 1500ms all ease-in-out;
    }

    .fade-up-desktop {
      transform: translateY(50px);
    }

    .fade-down-desktop {
      transform: translateY(-50px);
    }

    .fade-down-desktop.animate,
    .fade-up-desktop.animate {
      opacity: 1;
      transform: translateY(0);
    }

    .grow-left-desktop,
    .grow-right-desktop {
      transform: scaleX(0);
      transform-origin: left;
      transition: 1200ms all ease-in-out;
      -webkit-transition: 1200ms all ease-in-out;
    }

    .grow-left-desktop {
      transform-origin: right;
    }

    .grow-right-desktop {
      transform-origin: left;
    }

    .grow-left-desktop.animate,
    .grow-right-desktop.animate {
      transform: scaleX(1);
    }

    .grow-down-desktop,
    .grow-up-desktop {
      transform: scaleY(0);
      transition: 1200ms all ease-in-out;
      -webkit-transition: 1200ms all ease-in-out;
    }

    .grow-up-desktop {
      transform-origin: bottom;
    }

    .grow-down-desktop {
      transform-origin: top;
    }

    .grow-down-desktop.animate,
    .grow-up-desktop.animate {
      transform: scaleY(1);
    }

    .stagger-desktop-1 {
      transition-delay: 300ms;
    }

    .stagger-desktop-2 {
      transition-delay: 600ms;
    }

    .stagger-desktop-3 {
      transition-delay: 900ms;
    }

    .stagger-desktop-4 {
      transition-delay: 1200ms;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  @media screen and (max-width: 767px) {
    .fade-down-mobile,
    .fade-up-mobile {
      opacity: 0;
      transition: 1500ms all ease-in-out;
      -webkit-transition: 1500ms all ease-in-out;
    }

    .fade-up-mobile {
      transform: translateY(50px);
    }

    .fade-down-mobile {
      transform: translateY(-50px);
    }

    .fade-down-mobile.animate,
    .fade-up-mobile.animate {
      opacity: 1;
      transform: translateY(0);
    }

    .grow-left-mobile,
    .grow-right-mobile {
      transform: scaleX(0);
      transition: 1200ms all ease-in-out;
      -webkit-transition: 1200ms all ease-in-out;
    }

    .grow-left-mobile {
      transform-origin: right;
    }

    .grow-right-mobile {
      transform-origin: left;
    }

    .grow-left-mobile.animate,
    .grow-right-mobile.animate {
      transform: scaleX(1);
    }

    .grow-down-mobile,
    .grow-up-mobile {
      transform: scaleY(0);
      transform-origin: top;
      transition: 1200ms all ease-in-out;
      -webkit-transition: 1200ms all ease-in-out;
    }

    .grow-up-mobile {
      transform-origin: bottom;
    }

    .grow-down-mobile {
      transform-origin: top;
    }

    .grow-down-mobile.animate,
    .grow-up-mobile.animate {
      transform: scaleY(1);
    }

    .fade-mobile {
      opacity: 0;
      transition: 1000ms all ease-in-out;
      -webkit-transition: 1000ms all ease-in-out;
    }

    .fade-mobile.animate {
      opacity: 1;
    }
  }
}
