ul {
  list-style: none outside none;
  margin-left: 0;
  white-space: nowrap;

  li {
    white-space: normal;
    word-break: keep-all;
  }

  li::before {
    content: none;
  }
}

ol {
  counter-reset: ol-counter;
  list-style: decimal outside none;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  white-space: nowrap;

  li {
    counter-increment: ol-counter;
    white-space: normal;
    word-break: keep-all;
  }
}

.breadcrumb ol {
  list-style: none;
  padding: 0;
  margin-left: 0;

  li {
    display: inline-block;
    font-size: var(--small);
  }

  a {
    font-size: inherit;
    text-decoration: none;
  }

  li::before {
    content: '';
    display: inline-block;
  }

  li::after {
    content: '/';
    display: inline-block;
    margin-left: .45rem;
  }

  li:last-of-type {
    &::after {
      content: '';
    }
  }
}
